import { Outlet, Link } from "react-router-dom";
import one from "./see-breeze/LOGOLOGO.png";
import Logo from "./Logo";
import Footer from "./Footer";
import { SocialIcon } from 'react-social-icons';

const Layout = () => {

  function toggleClick(){
    const navbarLinks = document.getElementsByClassName('navbarLinks')[0]
    const navbarLinks1 = document.getElementsByClassName('navbarLinks')[1]
    const navbarLinks2 = document.getElementsByClassName('navbarLinks')[2]
    const navbarLinks3 = document.getElementsByClassName('navbarLinks')[3]
    const socialContainer = document.getElementsByClassName('socialContainerUL')[0]
    const socialContainer2 = document.getElementsByClassName('socialContainerUL')[1]
    const socialContainer3 = document.getElementsByClassName('navbarLinksMedia')[0]
    const socialContainer4 = document.getElementsByClassName('navbarLinksMedia')[1]



    const change = navbarLinks.classList.toggle('active')
    const change1 = navbarLinks1.classList.toggle('active')
    const change2 = navbarLinks2.classList.toggle('active')
    const change4 = socialContainer.classList.toggle('active')
    const change5 = socialContainer2.classList.toggle('active')
    const change6 = socialContainer3.classList.toggle('active')
    const change7 = socialContainer4.classList.toggle('active')
    const change8 = navbarLinks3.classList.toggle('active')



    console.log("change", change, change1,change2,change4,change5,change6, change7, change8)
  }

  return (
    <div>
      <nav>
        <ul className="navbarContainer">
          
          <div className="logo">
            <Logo/>
          </div>
          <a href="##" onClick={toggleClick} className="toggle-button">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
          </a>
          
          <ul className="navbarLinkContainer">
            <Link className="navbarLinks" to="/">Home</Link>
            <Link className="navbarLinks" to="/gallery">Gallery</Link>
            <Link className="navbarLinks" to="/contact">Contact</Link>
            <Link className="navbarLinks" to="/valueprop">Value Prop</Link>
          </ul>
          <ul className="navbarSocialContainer">
          <ul className="socialContainer">
            <ul className="socialContainerUL">
            <SocialIcon className="navbarSocial" url="https://www.facebook.com/profile.php?id=100094035835598" />
            </ul>
            <ul className="socialContainerUL">
            <SocialIcon className="navbarSocial" url="https://www.linkedin.com/company/breeze-insulation/" />
            </ul>
          </ul>
          <h3 className="phoneNumber">
          904-710-2670
          </h3>
          </ul>
          <ul className="navbarLinkContainerHidden">
            <Link className="navbarLinksMedia" to="https://www.facebook.com/profile.php?id=100094035835598">Facebook</Link>
            <Link className="navbarLinksMedia" to="https://www.linkedin.com/company/breeze-insulation/">LinkedIn</Link>
            <h3 className="phoneNumberBIG">
          904-710-2670
            </h3>
          </ul>
          
        </ul>
      </nav>
      

      <Outlet />
      </div>
  )
};

export default Layout;