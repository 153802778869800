import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import Submission from "./pages/Submission";
import Footer from "./pages/Footer";
import Valueprop from "./pages/Valueprop";
import './App.css';

function App() {
  const [submit, setSubmit] = useState(false);

  return (
    submit === true ? (
      <Submission setSubmit={setSubmit} />
    ) : (
      <div className="pageContainer">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="contact" element={<Contact setSubmit={setSubmit} />} />
              <Route path="valueprop" element={<Valueprop />} />
            </Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    )
  );
}

export default App;
