import {useEffect, useRef, useState} from "react";
import emailjs from '@emailjs/browser';
import { form } from "react-router-dom";
import {useFormContext} from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";

const Contact = ({setSubmit}) => {
const [name,setName] = useState("")
const [email,setEmail] = useState("")
const [project,setProject] = useState("")
const [info,setInfo] = useState("")
const [verified,setVerified] = useState(false)
const [number,setNumber] = useState("")


const initialValues = {name: "", email: "", number: ""}
const [formValues,setFormValues] = useState(initialValues)
const [formErrors, setFormErrors] = useState({})
const [isSubmit,setIsSubmit] = useState(false);
const form = useRef()

// const sendEmail = (e) => {
  
//   emailjs.sendForm('service_5d8w80i', 'template_jh5ym86', form.current, 'BzXt2mZcmx9KHCBfH')
//   .then((result) => {
//   console.log(`form : ${form}`);

//   }, (error) => {
//       console.log(error.text);
//   });
//   e.target.reset()

//   console.log("email sent")
  
// }


//onchange logs out captca value and checks if we recieve data and if we do we set verified to true
function onChange(value) {
  console.log("Captcha value:", value)
  if(value){
  setVerified(true)
  } else {
    console.log("fill in captcha please PLEASE PLEASE")
  }
}

  //when we submit the form : validate the form values, and then and set the errors in the object to what they currently are
  //we set isSubmit to true after they are validated
  //
  const handleSubmit = (e) => {
    setFormErrors(validate(formValues))
    console.log("hanleSubmit reached")
    // sendEmail()
    setIsSubmit(true)
    console.log("hello you should be getting a form back")
      
    
    e.preventDefault()
  }

  //on re render check if keys of the form object are equal to 0 and is Submit is true
  useEffect(() => {
    console.log("useEffect Happening")
    console.log(formErrors);
    if(Object.keys(formErrors).length === 0 && isSubmit && verified) {
      console.log(formValues);
      setSubmit(true)
      emailjs.sendForm('service_htomsf4', 'template_jfxv0zh', form.current, 'BzXt2mZcmx9KHCBfH')
  .then((result) => {
  console.log(`form : ${form}`);

  }, (error) => {
      console.log(error.text);
  });
    } else {
      console.log("fill out captcha")
    }
  }, [formErrors]);

const handleChange = (e) => {
  console.log(e.target)
  const {name, value} = e.target;
  setFormValues({...formValues, [name]: value})
  console.log(formValues);
}

//validate takes in our values and if we dont have values for a form input it will assign an error an print to the console
const validate = (values) => {
  const errors ={}
  if (!values.name) {
    errors.name = "name is required";
  }
  if (!values.email) {
    errors.email = "email is required";
  }
  if (!values.number) {
    errors.number = "number is required";
  }
  if (!verified) {
    errors.verified = "please fill out ReCaptcha"
  }
  return errors;
}


    return (

      <div >
        <form className='formstyle' ref={form} onSubmit={(handleSubmit)}>

          
          <div className="inputContainer">
          <p className="error">{formErrors.name}</p>
          <input 
          className="contact"
          placeholder="Name"
          value={formValues.name}
          name='name'
          onChange={handleChange}
          />

          <p className="error">{formErrors.email}</p>
          <input 
          className="contact"
          placeholder="Email"
          value={formValues.email}
          name='email'
          onChange={handleChange}/>
          </div>


          <div className="inputContainer">
          <select
          className="contact"
          value={project}
          name='project'
          placeholder="Select Project Type"
          onChange={(e) => setProject(e.target.value)}>
            <option>Residential</option>
            <option>Commercial</option>
            <option>New Construction</option>
            <option>Metal Buildings</option>
            <option>Apartment Buildings</option>
            <option>Other</option>
          </select>

          <p className="error">{formErrors.number}</p>
          <input 
          className="contact"
          placeholder="Phone Number"
          value={formValues.number}
          name='number'
          onChange={(handleChange)}/>

         
          </div>

          <input
          className="contactInfo"
          placeholder="Information"
          value={info}
          name='info'
          onChange={(e) => setInfo(e.target.value)}/>
          
          
          <ReCAPTCHA
            sitekey="6Ld8KY4nAAAAACpBaYeacxqZHFBXS_Wc38MtBbFU"
            onChange={onChange}
  />
            <button 
            className="submit" 
            type="submit"
            // onClick={(e) => verified === true ? setSubmit(true) : e.preventDefault()  }
            validateForm={(name)}
            >
            Send Message
            </button>
            

            <p className="error">{formErrors.verified}</p>
            
            
        </form>
        

      </div>
    )
  };
  
  export default Contact;