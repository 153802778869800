const Home = () => {
    return (
      <div className="homeContainer">
    

    <div className="homeContainerLarge">

    <div className="homeContainerSmallParagraph">
    Our scientifically formulated, innovative attic insulation is made from 
    </div>

    <div className="homeContainerSmallParagraph">
    all-natural, inorganic, odorless and non-toxic materials.
    </div>

    <div className="homeContainerSmallParagraph">
    Our insulation is environmentally friendly and sustainable.
    </div>

    <div className="homeContainerSmallParagraph">
    It provides a significant reduction in KWH usage and hence lower utility bills.
    </div>

    <div className="homeContainerSmallParagraph">
    Our advanced insulation product is made in USA  and is  fire, water, mold and termite resistant.
    </div>

    <div className="homeContainerSmallParagraph">
    The product only requires a one-time application and may be applied over existing insulation.
    </div>

    <div className="homeContainerSmallParagraph">
    Our advanced material significantly reduces attic temperature.
    </div>

    <div className="homeContainerSmallParagraph">
    Our SEE-BREEZE product may be applied to
    </div>

    <div className="homeContainerSmallParagraph">
    residential, commercial, new construction, metal buildings and apartment buildings.
    </div>
    <div className="homeContainerSmallParagraph">
    Our products are versatile and can be applied as insulation to surfaces other than attics.
    </div>

    <div className="homeContainerSmallParagraph">
    We have three product lines: Ceramic Insulation, Fire Retardant Ceramic Paint and Specialty Coatings. 
    </div>

    <div className="homeContainerSmallParagraph">
    Currently serving Jacksonville, Florida, Ponte Vedra Beach, Florida and surrounding areas.
    </div>

    <div className="homeContainerSmallParagraph">
      Free estimates
    </div>

    <div className="homeContainerSmallParagraphBold">

    *Please note our insulation products are currently eligible 
    <div>
    for a Federal tax rebate of up to $1,200 for individual residences.*
    </div>
    </div>

    </div>

    
    </div>
    )
  };
  
  export default Home;