const Valueprop = () => {
    return (
        <div>

<div className="headingContainer">

<h2 className="valuepropheader">Safety:</h2>

<div className="homeContainerSmallParagraph">Our insulation product is totally safe because it is manufactured 100% with safe, inorganic, odorless, non-toxic, all-natural, environmentally friendly and sustainable ingredients which have been scientifically formulated into our product.  It has no man-made ingredients.  It is also essentially fire proof (fire resistant up to 3500+ degrees Fahrenheit).

</div>

</div>



<div className="headingContainer">
<h2 className="valuepropheader">Sustainability:</h2>

<div className="homeContainerSmallParagraph">Comprised 100% of water and inorganic materials, together with its 50+ year life, our insulation product is literally the definition of sustainability.  With increasing consumer awareness regarding energy conservation, our eco-friendly ceramic coating insulation is positioned to become a preferred choice for consumers seeking environmentally safe solutions and a sustainable future.  Our insulation can be used as a standalone product or in combination with traditional insulation products for even better total energy savings for the project.</div>

</div>

<div className="headingContainer">

<h2 className="valuepropheader">Cost:</h2>

<div className="homeContainerSmallParagraph">Our product cost is in line with existing insulation products. It has a reasonable payback period, estimated at 3-6 years. There is no need for repeat applications because of 50+ year life (essentially a permanent application) making it highly cost effective.</div>

</div>

<div className="headingContainer">

<h2 className="valuepropheader">Performance and Science:</h2>

<div className="homeContainerSmallParagraph">Our ceramic insulation product works because by placing a layer of high emissivity material (reflects over 90% infrared radiant heat), such as our product, in the attic on the roof deck (and/or attic sides), it acts as a radiant barrier and decreases the amount of infrared (IR) radiant heat, as well as ultra-violet (UV) and visible light that reaches the attic and hence the traditional attic insulation below.  IR is responsible for roughly 57 percent of heat load on a building.  While we are a young organization, to date we have documented a 20% to 38% energy savings with our product used as a standalone application.  Our product can be used both as a standalone application or in conjunction with existing insulation.</div>

<div className="homeContainerSmallParagraph">Surface emissivity is why our product is effective.  This factor is often overlooked by thermal conductivity values in traditional products.  Both are important in that they address different methods of heat transfer (infrared radiation vs conduction) which is why they can work well together in the same space. Ceramic coatings are not given an R-value rating like traditional insulation materials. Instead, they are rated by “emissivity,” the measure of both their ability to reflect heat and the amount of heat that is loaded onto a surface.  Additionally, applying our ceramic coating  to an attic deck or sides also creates a “cave effect,” helping to maintain a consistent temperature within the attic by preventing heat transfer and heat loading onto the building.</div>

<div className="homeContainerSmallParagraph">
In some applications infrared radiation can play a bigger role than conduction, particularly in hotter climates.  Because our product acts as both an insulator and an isolator it prevents heat transfer and heat loading onto a building. This means that heat will not transfer into or out of a building.  For this reason, although our product has not been tested in colder climates, our ceramic engineer is confident that our products will perform as effectively in cold areas of the U.S., Canada, etc., due to the science involved.  He has personally applied and tested other ceramic insulation products in cold weather settings. 
</div>

</div>

<div className="headingContainer">

<h2 className="valuepropheader">Comparison with Traditional Insulation:</h2>

<div className="homeContainerSmallParagraph">
    ·         Many traditional insulation products have a life of 15-20 years, requiring multiple applications to the building.  Our insulation product has a lifespan of 50+ years, which essentially makes it a one-time permanent application product.

    <br/>

·         Traditional insulation products may lose effectiveness with moisture or water exposure, while our product is very water resistant and generally not affected by moisture, water or salt air.
<br/>
·         Traditional products are not fire resistant, while our product is extremely fire resistant and has an initial melting point of 3500+ degrees Fahrenheit, allowing more time for building occupants to escape a building fire.
<br/>
·         In comparison with traditional metallic barrier products, our ceramic coating provides a superior radiant barrier because of the action of emissivity, which reduces total heat flux through the ceiling. Additionally, our product is more flexible in its application (reaches into to nooks and crannies and coats irregular surfaces). </div>

<div/>

</div>

<div className="headingContainer">

<h2 className="valuepropheader">Specific Additional Features:</h2>

<div className="homeContainerSmallParagraph">

· Three product lines:  Classic (#0727); Deluxe (#0806) and Satin (#1020).  The Satin product is translucent in appearance for greater possible applications.
<br/>
·  Truly a pure ceramic product (not paint or semi-ceramic product) unlike products by competitors
<br/>
·  Thin coating--1/16th-1/8th of an inch thick (the thickness between a dime and a quarter) 
<br/>
·  Manufactured solely by the Company in the USA          
<br/>
·  Highly resistant to water, mold, termites, fire and salt air  
<br/>
·  Can be painted over
<br/>
·  Can be applied over existing insulation
<br/>
·  Eligible for US Federal tax rebate of up to $1,200 for individual residences.  Businesses may be eligible for the 45L Federal Tax Credit of up to $5,000 per home for the construction of energy efficient homes.  Businesses may also be eligible for the 179D Federal Tax Deduction for developing energy efficient commercial buildings.
<br/>
·  Can be applied to attics as well as on top of or below (underlayment) roofs (can prolong the life of roof shingles), on the interior or exterior of walls, walls for new construction and crawl spaces.
<br/>
·  With new construction, the entire building framework can be sprayed, as well as the attic, before drywall installation to insulate the entire building
<br/>
·  Because it is comprised 100% of water and inorganic material, it does not create breathing or health issues
</div>

</div>

<div className="headingContainer">



<h2 className="valuepropheader">Applications:</h2>

<div className="homeContainerSmallParagraph">
·         Residential real estate (homes, apartments, condos)
<br/>
·         Commercial (office buildings, warehouses)
<br/>
·         New or existing construction
<br/>
·         Additional types of buildings (healthcare)
<br/>
·         Agricultural (livestock sheds and buildings)
<br/>
·         Metal roofs, buildings, trailers, RVs and shipping containers
</div>
</div>




</div>

    )
}

export default Valueprop