
import one from "./see-breeze/IMG_3763.MOV";
import two from "./see-breeze/breezeSaftey2.jpg";
import three from "./see-breeze/IMG_3477.jpg";
import four from "./see-breeze/IMG_6149.jpg";
import five from "./see-breeze/breezesafety.JPG";
import movie2 from "./see-breeze/torchTest.MOV"


const Gallery = () => {
    return( 
    <div className="galleryContainerLarge">
    <h1 >
      Gallery
    </h1>
    <div>
      <div className="galleryContainer">
      <div>
        <video src={one} autoPlay loop muted/>
      </div>
    <div style={{
      backgroundImage: `url(${two})`, 
    }}>
    </div>
    <div style={{
      backgroundImage: `url(${three})`,      
    }}>
    </div>
    <div style={{
      backgroundImage: `url(${four})`      
    }}>
    </div>
    <div style={{
      backgroundImage: `url(${five})`      
    }}>
    </div>
    
      <div>
        <video src={movie2} autoPlay loop muted/>
      </div>
    </div>
    </div>
    </div>
    )
  };
  
  export default Gallery;