const Footer = () => {
    return(
        
            <div className="footer">
            <h2>
                Breeze Insulation LLC
            </h2>
            <h3>
                breezeinsulation@gmail.com  
            </h3>
            
            <div>

            </div>
        </div>
        
    )
}

export default Footer;